
/* projects page */
.projects {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 5px;
  padding-top:10px;
  /* align-items: center; */
  /* justify-content: center; */
  /* background-color: #f5f5f5; */
}
/* .projects > div{
    background-color:orange;
} */

.index-projects > a {
  margin-right: 10px;
  padding-top:7px;
  padding-bottom:7px;
  padding-left:15px;
  padding-right:15px;
  
}
/* .index-projects > a:active{
  background-color: #f5f5f5;
} */


/* projects details navigation arrows */
.styles-module_prev__KqFRp{
  color: #000 !important;
}
.styles-module_next__1uQwZ{
  color: #000 !important;
}
.styles-module_close__2I1sI{
  color: #000 !important;
}

/* projects page hover text */
.insert-center{
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  visibility: hidden;
}

.project-div:hover .insert-center{
  visibility: visible;
}